module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.3.1_iip3m7gtmswe5bpr6x3ti6neve/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PVTWT3V","dataLayerName":"dataLayer","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.3.0_iip3m7gtmswe5bpr6x3ti6neve/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YEJ2KCHLNP"],"gtagConfig":{"anonymize_ip":true,"dimension1":"light"}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-facebook-pixel@1.0.8/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"3514417221923887"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.3.3_qyabkb5h6b7mifciv426iu4uca/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
